@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.funding-request {
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(53, 53, 54, 0.25);

  &__block {
    display: flex;
    margin-bottom: 1rem;

    @media #{$sm-down} {
      border-radius: 0 0 8px 8px;
      flex-direction: column;
    }
  }
}
