@import '../../styles/colors';
@import '../../styles/breakpoints';

.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  header {
    background-color: $deep-azul;
    margin-bottom: 30px;
  }

  &__content {
    flex: 1 0 auto;
  }

  &__footer {
    background-color: $deep-azul;
    margin-top: 2rem;
  }
}
