@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.navbar-item {
  position: relative;
  text-align: center;
  white-space: nowrap;

  @media #{$sm-down} {
    position: unset;
  }
}

.navbar-link {
  border-bottom: 6px solid transparent;
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 15px 25px 8px;

  @media #{$sm-down} {
    align-items: center;
    border-bottom: 1px solid $regal-blue;
    display: flex;
    padding: 15px;
    width: 100%;
  }

  &:hover,
  &:hover i::before {
    color: $lt-green;
  }

  &__icon {
    display: block;
    font-size: 24px;

    @media #{$sm-down} {
      display: inline-block;
      font-size: 18px;
      margin-right: 10px;
    }

    &::before {
      color: $white;
    }
  }

  &--active {
    border-color: $lt-green;
    color: $lt-green;

    i::before,
    &:hover {
      color: $lt-green;
    }
  }
}
