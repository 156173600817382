@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.style {
  color: $og-link-blue;
  font-weight: bold;
}

.resources-menu {
  background: $deep-azul;
  border-top: 1px solid $regal-blue;
  margin-top: -30px;
  padding: 30px 20px;

  &__title {
    color: $deep-azul;
    font-family: $lato;
    font-size: 27px;
    font-weight: bold;
    margin-top: 18px;
  }

  p {
    color: $white;
    font-size: 17px;
    margin-bottom: 16px;

    @media #{$sm-down} {
      font-size: 15px;
    }
  }

  h1 {
    color: $white;
    font-size: 48px;
    line-height: 1.25;

    @media #{$sm-down} {
      font-size: 27px;
    }
  }

  &__disclosures {
    padding-top: 10px;
  }
}

.down-arrow {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid $og-link-blue;
  height: 0;
  width: 0;
}

.resources-menu__links {
  display: flex;
  flex-flow: row wrap;

  @media #{$md-down} {
    flex-flow: row nowrap;
  }

  li {
    display: flex;

    @media #{$xs-up} {
      padding: 10px 0;
      padding-right: 15px;
    }

    @media #{$md-up} {
      width: 25%;
    }

    @media #{$md-down} {
      width: 33.33%;
    }

    @media #{$xs-down} {
      width: 100%;
    }
  }
}
