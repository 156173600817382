@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.offer-agent {
  background-color: $white;
  border-bottom: 1px solid $light-gray;
  border-radius: 8px 8px 0 0;
  display: flex;
  padding: 0;
  width: 100%;

  @media #{$md-down} {
    flex-direction: column;
  }

  &__phone {
    color: $og-link-blue;

    &--bold {
      font-size: 20px;
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__right {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media #{$md-down} {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__left-box {
    align-items: center;
    display: flex;

    @media #{$md-down} {
      width: 100%;
    }

    &--header {
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 7px 10px;
      text-transform: uppercase;

      @media #{$md-down} {
        font-size: 13px;
      }
    }
  }
  &__right-box {
    display: flex;
    font-size: 14px;
    padding: 0 15px;

    @media #{$md-down} {
      border-top: 1px solid $light-gray;
      flex-direction: column;
      font-size: 15px;
      padding: 15px;
      width: 100%;
    }

    &--name {
      font-weight: bold;

      @media #{$md-up} {
        padding-top: 6px;
      }
    }

    &--phone {
      align-items: center;
      display: flex;
      padding-left: 15px;

      @media #{$md-down} {
        padding: 0;
      }
    }

    &--email {
      align-items: center;
      color: $og-link-blue;
      display: flex;
      padding-left: 15px;

      @media #{$md-down} {
        padding: 0;
      }
    }
    &--dot {
      color: $dark-gray;
      font-size: 20px;
      margin: -8px 15px 0 0;
    }
  }
}
