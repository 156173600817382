@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';

.matche-content {
  background-color: $white;
  border-radius: 0 0 8px 8px;
  display: flex;
  margin-bottom: 1.5rem;
  padding: 0;
  width: 100%;

  &__info {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    width: 100%;

    @media #{$sm-down} {
      flex-direction: column;
      padding: 15px;
    }
  }
}
