@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.status {
  background-color: $white;
  border-radius: 0 8px 8px 0;
  color: $deep-azul;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  width: calc(100% - 278px);

  @media #{$md-down} {
    padding: 20px;
    width: 68%;
  }

  @media #{$sm-down} {
    border-radius: 0 0 8px 8px;
    padding: 20px 15px;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    &--label-name {
      color: $dark-gray;
      font-size: 15px;
      line-height: 1.47;
      margin-bottom: 3px;

      @media #{$sm-down} {
        font-size: 11px;
        margin-bottom: 4px;
      }
    }

    &--label-val {
      font-size: 22px;

      @media #{$sm-down} {
        color: $deep-azul;
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
}

.border-top-right-radius-zero {
  border-top-right-radius: 0;
}
