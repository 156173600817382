@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.resources-menu-mobile {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  position: relative;
  width: 100%;

  &__collapse {
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-basis: auto;
    flex-grow: 1;

    @media #{$sm-down} {
      background: $white;
      display: block;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      transform: translateY(100%);
      transition: all 0.5s ease;
      visibility: hidden;
      width: 100%;
      z-index: 1;
    }
  }

  &__collapse--show {
    @media #{$sm-down} {
      left: 0;
      opacity: 1;
      transform: translateX(0) translateY(30%);
      transition: 0.5s ease all;
      visibility: visible !important;
    }
  }

  &__nav {
    display: flex;
    flex-grow: 1;
    margin-left: 30px;

    @media #{$sm-down} {
      flex-direction: column;
      margin-left: 0;
    }
  }

  &__text {
    color: $black;
    font-weight: bold;
    padding-left: 15px;
    padding-top: 15px;

    i {
      &::before {
        color: $smoke;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__close {
    float: right;
    padding-right: 15px;
  }

  &__item {
    .navbar-link {
      border-color: transparent;
      color: $black;

      &--active {
        text-decoration: underline;
        text-decoration-color: $hopper-green;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
      }

      &:hover {
        background: rgba(0, 178, 159, 0.1);
      }
    }

    i {
      &::before {
        color: $hopper-green;
        display: inline-block;
      }
    }
  }
}
