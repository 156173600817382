@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';

.offers-cta {
  &__btn {
    background: $og-link-blue;
    border: 1px solid transparent;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    height: 50px;
    line-height: 1.42857143;
    margin: 0;
    min-height: initial;
    padding: 10px 20px;
    text-align: center;
    touch-action: manipulation;
    transition: all 0.3s;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    @media #{$md-down} {
      font-size: 15px;
      height: 35px;
      padding: 6px 12px;
      width: 100%;
    }

    &:hover {
      color: $white;
    }
  }
}
