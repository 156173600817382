@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.delete-modal {
  padding-bottom: 30px;
  padding-top: 30px;

  &__text,
  &__error-message {
    font-size: 17px;
    line-height: 1.65;
    text-align: center;

    @media #{$sm-down} {
      font-size: 15px;
      line-height: 1.47;
    }
  }

  &__error-message {
    color: $fashion-fuchsia;
    margin-bottom: 10px;

    @media #{$sm-down} {
      margin-bottom: 4px;
    }
  }

  &__text {
    color: $dark-gray;
    margin-bottom: 38px;

    @media #{$sm-down} {
      margin-bottom: 18px;
    }
  }

  &__cta-block {
    margin: auto;
    padding: 0;

    &--cancel,
    &--delete {
      font-weight: 700;
      height: 50px;
      min-width: 187px;
      padding: 2px;

      @media #{$sm-down} {
        width: 100%;
      }
    }

    &--cancel {
      background: $white;
      border: 1px solid $og-link-blue;
      color: $og-link-blue;
      margin: 0 28px;

      @media #{$sm-down} {
        margin: 0 0 15px;
      }
    }

    &--delete {
      background: $og-link-blue;
      border: 1px solid $og-link-blue;
      color: $white;
      margin: 0;
    }
  }

  &__inprocess {
    text-align: center;

    &--blink {
      animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }
}
