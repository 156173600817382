@import '../../../styles/colors';

.openOpportunity {
  &__modal {
    padding: 10px;
  }

  &__heading {
    color: $tangaroa;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    margin: 10px 0;
    text-align: center;
  }

  &__copy {
    padding: 10px 30px;
  }

  &__concierge {
    border-bottom: 1px solid $steam;
    border-top: 1px solid $steam;
    display: flex;
    justify-content: center;
    padding: 10px 0;

    &-left {
      border-right: 1px solid $steam;
      color: $smoke;
      padding-right: 20px;
      text-align: right;
    }

    &-right {
      margin-left: 20px;
    }

    &-number {
      color: $og-link-blue;
      cursor: pointer;
    }
  }

  &__buttons {
    margin-top: 20px;
    text-align: center;

    button {
      margin: 0 0.5rem;
    }
  }
}
