@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.photo {
  display: inline-block;
  vertical-align: top;

  &__image {
    border: 2px solid $black;
    border-radius: 100%;
    height: 80px;
    width: 80px;

    @media #{$md-down} {
      height: 65px;
      width: 65px;
    }

    @media #{$sm-down} {
      height: 90px;
      width: 90px;
    }
  }
}
