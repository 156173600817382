@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.history {
  margin-top: 0.5rem;

  &__title {
    color: $deep-azul;
    font-family: $lato;
    font-size: 27px;
    margin-bottom: 18px;

    @media #{$sm-down} {
      font-size: 22px;
    }
  }
}
