@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.tabular-init {
  background-color: $white;
  border-collapse: collapse;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 0 30px 15px;
  table-layout: fixed;
  width: 100%;

  &__caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  &__tr {
    padding: 0.35em;

    &:nth-of-type(odd) {
      background-color: $black-haze;
    }
  }

  &__th,
  &__td {
    padding: 0.625em;
    text-align: center;

    &:first-child {
      padding-left: 12px;
    }
  }

  &__th {
    color: $silver-chalice;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.2px;
    line-height: normal;
    padding: 19px 0 15px;
    text-align: left;
    text-transform: uppercase;
  }

  &__td {
    border: 0 !important;
    color: $tundora-approx;
    font-size: 15px;
    line-height: 1.87;
    padding: 6px 0;
    text-align: left;
  }

  @media #{$sm-down} {
    &__caption {
      font-size: 1.3em;
    }

    &__thead {
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    &__tr {
      display: block;
      margin-bottom: 0.625em;
    }

    &__td {
      display: block;
      font-size: 0.8em;
      text-align: right;

      &::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
