@import '../../../styles/breakpoints';

.credit-cards {
  &__grid {
    @media #{$xs-down} {
      grid-template-columns: repeat(auto-fill, 100%) !important;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;

    &-image {
      margin: 0 auto 25px;
      width: 180px;
    }
  }
}
