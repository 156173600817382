@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.request-not-found {
  &__label,
  &__text {
    font-size: 15px;
    line-height: 1.47;
  }

  &__label {
    color: $electric-salmon;
    font-weight: 700;
    text-transform: uppercase;
  }
}
