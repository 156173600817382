@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.hero-image {
  &__construction-box {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(53, 53, 54, 0.25);
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 40px;
    padding: 25px 30px 30px;

    @media #{$md-down} {
      padding: 20px;
    }

    @media #{$sm-down} {
      align-items: center;
      flex-direction: column;
      margin-top: 10px;
      padding: 15px;
    }
  }

  &__construction-title {
    color: $deep-azul;
    font-family: $georgia;
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 30px;

    @media #{$sm-down} {
      font-size: 27px;
      letter-spacing: -0.1px;
      line-height: 1.19;
      margin-bottom: 27px;
    }
  }

  &__left-side {
    max-width: 525px;
    width: 50%;

    @media #{$sm-down} {
      max-width: 100%;
      width: 100%;
    }
  }

  &__help-title {
    color: $deep-azul;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.56;
    margin-bottom: 15px;

    @media #{$sm-down} {
      font-size: 15px;
      line-height: 1.33;
    }
  }

  &__help-list {
    li {
      font-size: 17px;
      line-height: 1.65;
      margin-bottom: 5px;

      @media #{$sm-down} {
        font-size: 15px;
        line-height: 1.47;
        margin-bottom: 10px;
      }

      &::before {
        background-color: #08c177;
        border-radius: 100%;
        content: '';
        display: inline-block;
        height: 5px;
        left: 0;
        margin-right: 10px;
        top: 11px;
        width: 5px;
      }
    }
  }

  &__right-imgblock {
    margin-top: -84px;
    width: 40%;

    @media #{$md-down} {
      margin-top: -74px;
      width: 50%;
    }

    @media #{$sm-down} {
      margin-top: 10px;
      width: 100%;
    }
  }

  &__action-cta {
    background: $og-link-blue;
    border: 1px solid transparent;
    border-radius: 3px;
    color: $white !important;
    display: inline-block;
    font-size: 18px;
    height: 50px;
    margin-bottom: 30px;
    padding: 12px;
    text-align: center;

    &:hover {
      color: $white !important;
      cursor: pointer;
    }

    @media #{$xs-down} {
      font-size: 17px;
      width: 100%;
    }
  }
}
