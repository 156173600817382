@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.signup {
  margin-top: 20px;

  &__title {
    color: $lt-green;
    font-size: 19px;
    font-weight: bold;
    line-height: 2.5;
    margin-bottom: 9px;
    text-align: center;

    @media #{$sm-down} {
      font-size: 16px;
    }
  }

  &__content {
    text-align: center;
  }

  &__header {
    color: $deep-azul;
    font-size: 40px;
    margin-bottom: 20px;
    text-align: center;

    @media #{$sm-down} {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }

  &__container {
    margin: auto;
    max-width: 620px;
  }

  &__subtitle {
    margin-bottom: 20px;
    text-align: center;
  }

  &__footer {
    margin-top: 20px;
    text-align: center;
  }

  .progress-block {
    display: inline-block;
    margin-bottom: 20px;
    min-width: 360px;

    &__text {
      color: #999;
      font-size: 14px;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .progressbar-container {
    height: 7px;
  }
}
