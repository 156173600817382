@import '../../styles/colors';

.progressbar-container {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  display: flex;
  font-size: 0.75rem;
  height: 1rem;
  overflow: hidden;
}

.progressbar {
  background: $lt-green;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  transition: width 600ms ease;
  white-space: nowrap;
}
