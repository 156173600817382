@import '../../styles/breakpoints';
@import '../../styles/colors';

.block {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(53, 53, 54, 0.25);
  margin-bottom: 20px;
  padding: 30px;
}

@media #{$sm-down} {
  .block {
    margin-bottom: 15px;
    padding: 20px;
  }
}

.block__title {
  color: $black;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.block__text {
  color: $dark-gray;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  margin-bottom: 15px;
}
