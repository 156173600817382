@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.additional-funding {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(53, 53, 54, 0.25);
  margin-bottom: 1rem;
  padding: 30px;

  @media #{$sm-down} {
    padding: 15px;
  }

  &__inner-box {
    align-items: center;
    display: flex;
    max-width: 280px;

    &:hover {
      cursor: pointer;
    }
  }

  &__plus-icon {
    align-items: center;
    border: 2px solid $og-link-blue;
    border-radius: 100%;
    display: flex;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    margin-right: 15px;
    width: 40px;

    @media #{$sm-down} {
      font-size: 12px;
      height: 30px;
      width: 30px;
    }

    &::before {
      color: $og-link-blue;
    }
  }

  &__link-text {
    color: $og-link-blue;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.56;

    @media #{$sm-down} {
      font-size: 15px;
    }
  }
}
