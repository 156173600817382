@import '../../styles/colors';

.success-message {
  background-color: $lt-green;
  border-radius: 6px;
  color: $white;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.47;
  margin-top: 16px;
  padding: 5px;

  i {
    padding: 10px;

    &::before {
      color: $white;
    }
  }
}
