@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.entry-page {
  background-color: $white;
  margin-top: -30px;

  &__header {
    background-image: radial-gradient(circle at 50% 0, $mint-cream, $ziggurat 154%);

    &--contents {
      display: flex;
      justify-content: space-between;
      padding: 60px 0 108px;

      @media #{$xlg-up} {
        margin: 0 auto;
      }

      @media #{$md-down} {
        flex-direction: column;
        padding: 30px 0 110px;
      }
    }

    &--page-title {
      color: $deep-azul;
      font-size: 44px;
      margin-bottom: 15px;

      @media #{$lg-down} {
        font-size: 40px;
      }

      @media #{$sm-down} {
        font-size: 27px;
        line-height: 1.19;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    &--page-sub-title {
      color: $deep-azul;
      font-family: $lato;
      font-size: 27px;
      margin-bottom: 30px;

      @media #{$lg-down} {
        font-size: 24px;
      }

      @media #{$sm-down} {
        font-size: 22px;
        line-height: 1.36;
        text-align: center;
      }
    }
  }
}

.business-boost-list {
  font-size: 17px;
  max-width: 75%;

  @media #{$lg-down} {
    max-width: 90%;
  }

  &__li {
    display: flex;
    margin-bottom: 10px;

    @media #{$sm-down} {
      font-size: 15px;
      line-height: 1.47;
    }
  }

  .bl-lt-icon--Check {
    display: inline-block;
    padding: 6px 10px 0 0;

    &::before {
      color: $lt-green;
    }
  }
}

.business-boost-list-mobile {
  @media #{$md-down} {
    display: block;
    max-width: 100%;
  }
}

.emailstep-form {
  background-color: $deep-azul;
  border-radius: 4px;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  margin: 0 0 30px 30px;
  padding: 18px 22px 44px;
  width: 361px;

  @media #{$md-down} {
    margin-left: 0;
  }

  @media #{$sm-down} {
    padding: 13px 15px 18px;
    width: 100%;
  }

  &__title {
    color: $white;
    font-family: $lato;
    font-size: 27px;
    margin-bottom: 10px;

    @media #{$sm-down} {
      font-size: 22px;
      line-height: 1.36;
      text-align: center;
    }
  }

  &__email {
    &--label {
      color: $white;
      display: block;
      margin: 0;
      padding-bottom: 5px;
    }

    &--input {
      border: 1px solid $light-gray;
      border-radius: 4px;
      height: 50px;
      padding: 15px 20px;
      width: 100%;
    }
  }

  &__signup-cta {
    background-color: $electric-salmon;
    border-radius: 4px;
    color: $white;
    font-size: 18px;
    font-weight: bold;
    height: 50px;
    margin: 30px 0;
    padding: 13px 30px 15px;
    width: 100%;

    @media #{$sm-down} {
      margin: 15px 0;
    }
    &:hover {
      background-color: $electric-salmon;
      cursor: pointer;
    }
  }

  &__signin {
    color: $white;
    font-size: 13px;
    text-align: center;

    &--link {
      color: $white;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: $white;
      }
    }
  }
}

.separator-div {
  background-color: $deep-azul;
  height: 7px;
  margin: auto;
  max-width: 90%;

  @media #{$xlg-up} {
    max-width: 75%;
  }
}

.business-grow {
  display: flex;
  padding: 30px 60px;
  position: relative;

  @media #{$xlg-up} {
    margin: 0 auto;
    max-width: 1500px;
  }

  @media #{$md-down} {
    align-items: center;
    flex-direction: column;
    padding: 30px 40px;
  }

  &__img-block {
    max-height: 460px;
    max-width: 450px;
    width: 100%;

    @media #{$xxs-down} {
      max-height: 400px;
    }

    &--concierge {
      display: block;
      left: 0;
      margin: auto;
      position: relative;
      top: -140px;

      @media #{$md-down} {
        top: -130px;
      }

      @media #{$xxs-down} {
        max-height: -100px;
        min-width: 320px;
        top: -125px;
      }

      @media (max-width: 320px) {
        left: -30px;
      }
    }
  }

  &__content-block {
    &--title {
      color: $deep-azul;
      font-family: Georgia;
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 15px;
      text-align: center;

      @media #{$lg-down} {
        font-size: 34px;
      }

      @media #{$sm-down} {
        font-size: 27px;
      }
    }

    &--text {
      font-size: 17px;
      line-height: 1.65;
      margin-bottom: 30px;

      @media #{$sm-down} {
        font-size: 15px;
        margin-bottom: 20px;
      }
    }
  }
}

.business-grow-wrapper {
  display: flex;
  justify-content: space-evenly;

  @media #{$sm-down} {
    flex-direction: column;
  }

  &__box {
    margin: 0 10px;
    text-align: center;

    @media #{$sm-down} {
      margin: 0 0 20px;
    }

    .bl-lt-icon--CreditScore,
    .bl-lt-icon--Cash,
    .bl-lt-icon--Lock {
      display: inline-block;
      font-size: 40px;
      margin-bottom: 10px;

      @media #{$sm-down} {
        margin-bottom: 6px;
      }

      &::before {
        color: $hopper-green;
      }
    }

    &--label {
      color: $deep-azul;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.56;
      margin-bottom: 10px;

      @media #{$sm-down} {
        font-size: 15px;
      }
    }

    &--text {
      font-size: 17px;
      line-height: 1.65;
      margin: 12px;

      @media #{$sm-down} {
        font-size: 15px;
      }
    }
  }
}
