@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.upload-document {
  &__tbody {
    &-tr {
      position: relative;

      @media #{$sm-down} {
        border-bottom: 1px solid $smoke;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
      }
    }
  }

  &__td {
    border-bottom: 1px solid #d8d8d8;
    color: $dark-gray;
    font-size: 15px;
    line-height: normal;
    padding: 20px 15px;
    width: 40%;

    @media #{$sm-down} {
      border: 0;
      padding: 0;
      width: 100%;
    }

    &:first-child {
      width: 40%;
      word-break: break-word;

      @media #{$sm-down} {
        color: #000;
        font-weight: bold;
        line-height: 1.33;
        margin-bottom: 3px;
        width: 100%;
        word-break: break-all;
      }
    }

    &:nth-child(2) {
      @media #{$sm-down} {
        line-height: 1.33;
        margin-bottom: 2px;
        word-break: break-word;
      }
    }

    &:nth-child(3) {
      @media #{$sm-down} {
        font-size: 12px;
        line-height: 1.67;
        margin-bottom: 2px;
      }
    }

    &:last-child {
      @media #{$sm-down} {
        padding: 0;
      }
    }

    &-delete-icon {
      background-image: url('../images/shape-before.png');
      background-repeat: no-repeat;
      cursor: pointer;
      display: block;
      height: 18px;
      margin: 0 15px;

      width: 24px;
      @media #{$sm-down} {
        float: right;
      }

      &:hover {
        background-image: url('../images/shape-hover.png');
      }
    }

    i {
      padding-right: 5px;

      &::before {
        color: $lt-green;
      }
    }
  }
}
