@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.new-funding {
  &__request-box {
    align-items: center;
    background-color: $deep-azul;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(53, 53, 54, 0.25);
    justify-content: space-between;
    margin-bottom: 1rem !important;
    padding: 25px 30px;

    @media #{$md-down} {
      padding: 20px;
    }

    @media #{$sm-down} {
      padding: 14px;
    }
  }
  &__cta-block {
    padding: 0;

    @media #{$sm-down} {
      max-width: 100%;
    }
  }
  &__title-name {
    color: $lt-green;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.56;

    @media #{$sm-down} {
      font-size: 15px;
      line-height: 1.33;
      margin-bottom: 8px;
    }
  }
  &__text {
    color: $white !important;
    font-size: 17px !important;
    line-height: 1.65;
    margin: 0;

    @media #{$sm-down} {
      font-size: 15px !important;
      line-height: 1.47;
      margin-bottom: 30px;
    }
  }

  &__action-cta {
    background: $og-link-blue;
    border: 1px solid transparent;
    border-radius: 3px;
    color: $white;
    display: inline-block;
    font-size: 18px;
    height: 50px;
    padding: 12px 24px;
    text-align: center;
    width: 100% !important;

    &:hover {
      color: $white;
    }

    @media #{$md-down} {
      font-size: 15px;
      height: 35px;
      padding: 6px 12px;
    }
  }
}
