@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.documents {
  &__info-alert {
    background: $ocean-blue;
    border-radius: 3px;
    color: $white;
    display: flex;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.4;
    margin: 20px 0;
    padding: 7px 15px 7px 35px;
    position: relative;

    @media #{$sm-up} {
      height: 35px;
      max-width: 570px;
      width: 100%;
    }
  }

  &__info-icon::before {
    background-color: $white;
    border-radius: 50%;
    color: $ocean-blue;
    content: 'i';
    font-size: 13px;
    font-weight: 600;
    height: 15px;
    left: 12px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 5px;
    position: absolute;
    top: 10px;
    width: 15px;
  }

  &__subheader {
    border-bottom: 1px solid $steam;
    color: $black;
    font-family: $lato;
    font-weight: bold;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    padding-bottom: 0.5rem;
  }
}
