@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.tracker {
  padding-bottom: 20px;

  @media #{$sm-down} {
    padding: 0 0 10px;
  }

  &__title {
    color: $deep-azul;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;

    &--label {
      color: $lt-green;
      font-size: 15px;
      font-weight: 700;
      line-height: 1.47;
      text-transform: uppercase;
    }
  }
}

.custom-tracker {
  background-color: $light-grey;
  border-radius: 8.5px;
  display: flex;
  font-size: 0.75rem;
  height: 10px;
  margin-bottom: 17px;
  overflow: hidden;
  position: relative;

  @media #{$sm-down} {
    height: 17px;
  }

  &__box {
    background-color: $lt-green;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    transition: width 0.6s ease;
    white-space: nowrap;

    &--complete-profile {
      width: 12%;
    }

    &--upload-documents {
      width: 32%;
    }

    &--curating-offers {
      width: 50.5%;

      @media #{$md-down} {
        width: 52.5%;
      }

      @media #{$sm-down} {
        width: 54.5%;
      }
    }

    &--review-offers {
      width: 72%;
    }

    &--funding {
      width: 90.5%;

      @media #{$md-down} {
        width: 92.5%;
      }
    }

    &--loanfunded {
      width: 100%;
    }
  }
}

.tracker-block {
  position: relative;

  &__stage {
    display: flex;

    &--text {
      color: $smoke;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;
      width: 20%;

      @media #{$md-down} {
        font-size: 11px;
      }
    }

    &--text-active {
      color: $deep-azul;
    }
  }
}

.tracker-block-disabled {
  .tracker-block {
    .custom-tracker {
      background-color: $fluffy;
    }

    &__stage {
      &--text {
        color: $steam;
      }
    }
  }
}

.stage-divider {
  left: 0;
  position: absolute;
  top: -20px;
  width: 100%;

  i {
    display: block;
    font-size: 30px;
    position: absolute;
    top: 10px;

    @media #{$sm-down} {
      top: 14px;
    }

    &::before {
      color: $white;
    }
  }

  &__stage-one {
    left: 18%;
  }

  &__stage-two {
    left: 38%;
  }

  &__stage-three {
    left: 58%;
  }

  &__stage-four {
    left: 78%;
  }
}

.incomplete-alert {
  background-color: $fashion-fuchsia;
  border: solid 2px $white;
  border-radius: 100%;
  color: $white;
  display: none;
  font-size: 14px;
  font-weight: 700;
  height: 20px;
  line-height: 1.2;
  position: absolute;
  text-align: center;
  top: -5px;
  vertical-align: middle;
  width: 20px;

  @media #{$sm-down} {
    top: -2px;
  }

  &__stage-first {
    display: block;
    left: 10%;
  }

  &__stage-second {
    display: block;
    left: 30%;
  }

  &__stage-third {
    left: 50%;
  }

  &__stage-fourth {
    display: block;
    left: 70%;
  }

  &__stage-fifth {
    left: 90%;
  }

  &__stage-six {
    background-color: $deep-verde;
    font-size: 8px;
    left: 90%;
    line-height: 2;

    &::before {
      color: $white;
    }
  }
}
