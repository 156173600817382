@import '../../styles/breakpoints';
@import '../../styles/colors';

// Once Bootstrap is removed from the application, rename this to `modal`
.modal__wrapper {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 3;

  &--center {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &--show {
    opacity: 1;
    pointer-events: visible;
  }
}

.modal__content {
  background-color: $fluffy;
  border-radius: 5px;
  color: $charcoal;
  font-size: 17px;
  margin: 15% auto;
  padding: 15px;
  position: relative;
  width: 90%;

  &--center {
    text-align: center;
  }

  &--small {
    max-width: 300px;
  }

  &--medium {
    max-width: 500px;
  }

  &--large {
    max-width: 800px;
  }

  &--x-large {
    max-width: 1200px;
  }
}

.modal__close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 5px;

  &:hover,
  &:focus {
    color: $black;
    cursor: pointer;
    text-decoration: none;
  }

  @media #{$sm-down} {
    right: 10px;
    top: 0;
  }
}

.modal__title {
  color: $charcoal;
  font-family: 'Lato', sans-serif;
  font-size: 27px;
  font-weight: 700;
  padding: 10px 0;

  @media #{$sm-down} {
    font-size: 20px;
    padding: 0 0 10px;
  }
}

.pointer__events {
  pointer-events: none;
}
