@import '../../../styles/colors';

.credit-needed {
  margin-top: 23px;
  width: 100%;

  &__scores {
    display: flex;
    height: 17px;
    margin-bottom: 2px;
  }

  &__score {
    background-color: #d9f5fd;
    color: $grey-day;
    flex: 1;
    font-size: 10px;
    position: relative;
    text-align: left;

    .credit-needed__mark {
      background-color: $steam;
      bottom: -12px;
      height: 38px;
      left: 0;
      position: absolute;
      width: 1px;
    }

    .credit-needed__label {
      position: absolute;
      top: -23px;
    }

    &--start {
      .credit-needed__label {
        margin-left: -8px;
      }
    }

    &--end {
      text-align: right;

      .credit-needed__label {
        margin-left: -8px;
      }

      .credit-needed__mark {
        left: auto;
      }
    }
  }

  &__bars {
    display: flex;

    .credit-needed__bar {
      background-color: $fluffy;
      border-left: 1px solid $white;
      border-right: 1px solid $white;
      flex: 1;
      height: 10px;

      &:last-child {
        border-right: 0;
      }
    }
  }
}
