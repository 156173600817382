@import '../../styles/breakpoints';
@import './variables';

@mixin make-col($size, $gap, $columns: 12) {
  $gap-percent: $column-gap * (1 - ($size/$columns));
  flex: 0 0 calc(#{percentage($size / $columns)} - #{$gap-percent});
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: calc(#{percentage($size / $columns)} - #{$gap-percent});
}

.flex-column {
  flex-basis: 100%;
  flex-grow: 1;
  max-width: 100%;
  min-height: 1px;
  position: relative;
  width: 100%;

  @for $i from 1 through 12 {
    &-#{$i} {
      @include make-col($i, $column-gap);
    }
  }

  @media #{$xxs-up} {
    &--xs {
      flex: none;
      max-width: none;
    }

    @for $i from 1 through 12 {
      &--xs-#{$i} {
        @include make-col($i, $column-gap);
      }
    }
  }

  @media #{$xs-up} {
    &--small {
      flex: none;
      max-width: none;
    }

    @for $i from 1 through 12 {
      &--small-#{$i} {
        @include make-col($i, $column-gap);
      }
    }
  }

  @media #{$sm-up} {
    &--medium {
      flex: none;
      max-width: none;
    }

    @for $i from 1 through 12 {
      &--medium-#{$i} {
        @include make-col($i, $column-gap);
      }
    }
  }

  @media #{$md-up} {
    &--large {
      flex: none;
      max-width: none;
    }

    @for $i from 1 through 12 {
      &--large-#{$i} {
        @include make-col($i, $column-gap);
      }
    }
  }
}
