@import '../../../styles/colors';
@import '../../../styles/breakpoints';
@import '../../../styles/fonts';

.business-credit {
  &__date {
    color: $smoke;
    font-size: 0.625rem;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 14.425rem;

    @media #{$sm-down} {
      top: 12.625rem;
    }

    @media #{$xs-down} {
      top: 10.625rem;
    }
  }

  &__logo {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 15.325rem;

    @media #{$sm-down} {
      top: 13.325rem;
    }

    @media #{$xs-down} {
      top: 11.325rem;
    }

    img {
      width: 75px;
    }
  }

  &__score {
    color: $tangaroa;
    font-size: 4.625rem;
    font-weight: 800;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 6.625rem;

    @media #{$sm-down} {
      font-size: 3.625rem;
    }

    @media #{$xs-down} {
      top: 4.625rem;
    }
  }

  &__status {
    color: $tangaroa;
    font-size: 2.125rem;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 11.625rem;

    @media #{$sm-down} {
      font-size: 1.625rem;
      top: 10.625rem;
    }

    @media #{$xs-down} {
      top: 8.625rem;
    }
  }
}

.business-profile {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding: 20px 30px 30px;

  @media #{$sm-down} {
    padding: 0 15px 15px;
  }

  &__left-content {
    border-right: 1px solid $steam;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 50%;

    @media #{$md-down} {
      border-bottom: 1px solid $steam;
      border-right: 0;
      padding: 0 10px;
      width: 100%;
    }

    .business-profile__content-title {
      text-align: center;

      .bl-lt-icon--Question::before {
        color: $og-link-blue;
        font-size: 14px;
      }
    }

    .business-profile__link {
      bottom: 0;
      text-align: center;
    }
  }

  &__right-content {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;

    @media #{$md-down} {
      flex: 0 0 100%;
    }
  }

  &__content-title {
    color: $tangaroa;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    margin: 10px 0;

    @media #{$sm-down} {
      font-size: 15px;
      line-height: 1.33;
      text-align: center;
    }
  }

  &__credit-factors {
    padding-left: 20px;
  }

  &__score-factors {
    color: $deep-azul;
    font-size: 17px;
    line-height: 1.65;
    list-style-type: disc;
    margin-bottom: 10px;

    @media #{$sm-down} {
      font-size: 15px;
    }
  }

  &__link {
    display: block;
    font-weight: 700;
    margin: 1rem 0;
  }
}

.curious-box {
  background-color: $white;
  border: solid 1px rgba(163, 163, 163, 0.3);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
  padding: 20px 40px;
  text-align: center;

  @media #{$sm-down} {
    padding: 10px;
  }
}

.curious-text {
  font-weight: bold;
}

.lt-login-link {
  font-size: 14px;
}

.credit-scores {
  padding: 20px;

  &__header {
    border-bottom: 1px solid $light-gray;
    margin-bottom: 10px;
    text-align: left;

    &--title {
      font-family: $lato;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;

      @media #{$sm-down} {
        font-size: 15px;
        padding-right: 12px;
      }
    }
  }

  &__info {
    font-family: $lato;
    font-size: 17px;
    padding: 2px;
    text-align: left;

    @media #{$sm-down} {
      font-size: 15px;
    }
  }
}

.dispute-information {
  background-color: $white;
  border-radius: 4px;
  display: flex;

  @media #{$sm-down} {
    flex-direction: column;
    height: 520px;
    overflow: scroll;
  }

  &__img-block {
    max-width: 300px;
    min-width: 300px;

    @media #{$sm-down} {
      min-width: 100%;
    }
  }

  &__small-img-block {
    height: 100%;
  }

  &__content-block {
    padding: 50px;

    @media #{$sm-up} {
      max-height: 700px;
      overflow: auto;
    }

    @media #{$sm-down} {
      padding: 30px;
      text-align: center;
    }

    &--title {
      color: $lt-green;
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 10px;

      @media #{$sm-down} {
        font-size: 22px;
        line-height: 1.45;
        margin-bottom: 0;
      }
    }

    &--text {
      font-size: 17px;
      margin-bottom: 50px;

      @media #{$sm-down} {
        font-size: 14px;
        margin-bottom: 30px;
      }
    }

    &--question-box {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
}

.question-box {
  align-items: center;
  display: flex;
  margin-bottom: 12px;

  @media #{$sm-down} {
    flex-direction: column;
    margin-bottom: 8px;
  }

  &__text {
    color: $deep-azul;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;

    @media #{$sm-down} {
      font-size: 15px;
    }
  }

  &__answer {
    font-size: 17px;
    line-height: 1.65;

    @media #{$sm-down} {
      font-size: 15px;
      line-height: 1.47;
    }

    &--link {
      color: $og-link-blue !important;
      cursor: pointer;
    }
  }

  &__bl-lt-icon {
    font-size: 30px;
    margin-right: 10px;

    @media #{$sm-down} {
      margin-right: 0;
    }

    &::before {
      color: $lt-green;
    }
  }
}

.business-profile__details {
  .modal__content--x-large {
    max-width: 980px;
    padding: 0;
  }
}

.user-guide {
  padding: 20px;
  text-align: center;

  &__img-section {
    margin: auto;
    margin-bottom: 1.5rem;
    max-width: 280px;
    position: relative;

    &--right-arrow {
      left: -70px;
      position: absolute;
      top: 10px;
    }

    &--left-arrow {
      position: absolute;
      right: -65px;
      top: 55px;
    }

    &--right-arrow,
    &--left-arrow {
      @media #{$xs-down} {
        display: none;
      }
    }

    &--right-arrow-bottom {
      top: 65px;
    }
  }

  &__title {
    color: $deep-azul;
    font-family: $lato;
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;

    @media #{$sm-down} {
      font-size: 22px;
      text-align: left;
    }
  }

  &__text {
    font-size: 17px;
    line-height: 1.65;
    margin-bottom: 1.5rem;
    text-align: center;

    @media #{$sm-down} {
      font-size: 15px;
      line-height: 1.47;
      text-align: left;
    }

    &--bold {
      color: $deep-azul;
      font-weight: bold;
    }
  }

  &__next-step {
    margin-bottom: 1rem;
    margin-left: auto;

    &--cta {
      background: $og-link-blue;
      border: 1px solid transparent;
      border-radius: 3px;
      color: $white;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      height: 50px;
      line-height: normal;
      margin: 0;
      max-width: 300px;
      min-height: initial;
      padding: 2px;
      text-align: center;
      transition: all 0.3s;
      vertical-align: middle;
      white-space: nowrap;
      width: 100%;

      @media #{$sm-down} {
        font-size: 15px;
        height: 35px;
      }

      @media #{$xxs-down} {
        max-width: 100%;
      }
    }
  }
}
.equifax-communication-fails-bg-blur-img {
  max-width: 600px;
  width: 100%;

  @media #{$sm-down} {
    min-width: 100%;
  }

  .equifax-communication-fails-bg-blur-img-desktop {
    @media #{$sm-down} {
      display: none;
    }
  }

  .equifax-communication-fails-bg-blur-img-mobile {
    display: none;

    @media #{$sm-down} {
      display: block;
    }
  }
}

.equifax-communication-fails-bg-text {
  background-color: $color-light-grey216;
  color: $black;
  font-weight: 500;
  left: 50%;
  padding: 20px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 442px;

  @media #{$xs-down} {
    width: 390px;
  }

  @media #{$xxs-down} {
    width: 276px;
  }
}

.credit-score-factors {
  padding-left: 25px;

  .score-factors-list {
    color: $deep-azul;
    font-size: 17px;
    line-height: 1.65;
    list-style-type: disc;
    margin-bottom: 10px;

    @media #{$sm-down} {
      font-size: 15px;
    }
  }

  .notscore-factors-list::marker {
    color: $lt-green;
  }
}

.notscored-help {
  padding-top: 1.5rem;
}
.notscored-help-list {
  padding-left: 14px;
  li {
    font-size: 15px;
    line-height: 1.65;
    list-style: decimal;
    margin-bottom: 5px;
    padding-left: 5px;
    position: relative;
  }
}
