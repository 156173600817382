$lt-green: #08c177;
$deep-azul: #101f30;
$electric-salmon: #ff704d;
$deep-verde: #078181;
$og-link-blue: #00aeef;
$secondary-blue: #0069ba;
$ocean-blue: #2187c1;
$hopper-green: #00b29f;
$fashion-fuchsia: #d93551;
$twinkle-twinkle: #ffc507;
$hopper-gradient: radial-gradient(circle at 50% 0, #08c177, #078181);
$deep-azul-gradient: radial-gradient(circle at 50% 0, #317298, #101f30);
$og-blue-gradient: radial-gradient(circle at 50% 0, #82cce0, #2187c1);
$mint-green-gradient: radial-gradient(circle at 50% 0, #e9fcf4, #8ab5b5);
$regal-blue: #1f3d5e;
$black: #000;
$charcoal: #333;
$dark-gray: #454545;
$gray-darken-2: #687073;
$gray-darken-3: #4b5155;
$grey-day: #666;
$smoke: #a3a3a3;
$steam: #e1e1e1;
$fluffy: #f5f5f5;
$white: #fff;
$light-gray: #ddd;
$silver-chalice: #adadad;
$tundora-approx: #4e4e4e;
$black-haze: #f1f3f3;
$dove-gray: #717171;
$light-grey: #d8d8d8;
$vivid-cerulean: #00aeef;
$tangaroa: #0c2238;
$color-light-grey216: #d8d8d8;
$mint-cream: #e9fcf4;
$ziggurat: #8ab5b5;
$amour: #ffebee;
$color-lt-red: #d93551;
