/* stylelint-disable */
/* LendingTree Grove Icon Font */
@font-face {
  font-family: 'GroveIcons';
  src: url('./fonts/grove.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

[class^='bl-grove-icon--'],
[class*=' bl-grove-icon--'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'GroveIcons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bl-grove-icon--Calendar::before {
  content: '\e925';
  color: #0069ba;
}
.bl-grove-icon--Envelope::before {
  content: '\e92e';
  color: #0069ba;
}
.bl-grove-icon--MobilePhone::before {
  content: '\e98b';
  color: #000000;
}
/* stylelint-enable */
