@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.funded-loans {
  &__title {
    color: $deep-azul;
    font-family: $lato;
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 15px;

    @media #{$sm-down} {
      font-size: 22px;
    }
  }

  &__mobile {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    max-height: 335px;
    overflow-y: auto;
    padding: 13px 15px 19px;
  }
}
