@import '../../../styles/colors';

.speedometer {
  &__pointer {
    &--poor {
      fill: #07a67b;
    }

    &--fair {
      fill: #08b978;
    }

    &--good {
      fill: $lt-green;
    }

    &--excellent {
      fill: #08ba78;
    }
  }
}
