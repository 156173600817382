@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.required-documents {
  &__tbody {
    &-tr {
      position: relative;

      @media #{$sm-down} {
        border-bottom: 1px solid $smoke;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
      }
    }
  }

  &__td {
    border-bottom: 1px solid #d8d8d8;
    color: $dark-gray;
    font-size: 15px;
    line-height: normal;
    padding: 20px 15px;

    @media #{$sm-down} {
      border: 0;
      padding: 0;

      .modal__wrapper {
        background-color: rgba(0, 0, 0, 0.5);
        font-style: normal;
        word-break: normal;
      }
    }

    &-green-bold {
      color: $lt-green !important;
      font-style: normal;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    &:first-child {
      color: $smoke;
      font-style: italic;
      padding-left: 35px;
      word-break: break-word;

      @media #{$sm-down} {
        line-height: 1.33;
        margin-bottom: 3px;
        padding-left: 0;
        width: 100%;
        word-break: break-all;
      }
    }

    &:nth-child(2) {
      @media #{$md-down} {
        padding-left: 0;
      }
    }

    &:last-child {
      padding-bottom: 5px;
      padding-left: 30px;
      @media #{$sm-down} {
        padding: 0;
      }
    }
  }

  &__tooltip {
    color: $og-link-blue;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;

    @media #{$sm-down} {
      color: $black;
    }
  }
}
