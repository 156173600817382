.thankyou {
  &__spinner-animation {
    animation: rotation 2s infinite linear;
  }
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}
