@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.recommendation {
  &__block {
    border-right: 1px solid $steam;
    margin-bottom: 15px;

    @media #{$md-down} {
      border-right: 0;
    }

    @media #{$sm-down} {
      margin-bottom: 10px;
    }
  }
}

.recommendation-title {
  color: $vivid-cerulean;
  cursor: pointer;
  display: flex;
  font-size: 17px;
  letter-spacing: normal;
  line-height: 1.56;
  margin-bottom: 15px;
  margin-right: 0.5rem;
  padding-right: 15px;
  width: 100%;

  @media #{$md-down} {
    border-top: 1px solid $steam;
    padding: 10px 4px;
  }

  @media #{$sm-down} {
    font-size: 15px;
  }

  &__active {
    align-items: center;
    color: $black;
    justify-content: space-between;

    @media #{$md-down} {
      color: $vivid-cerulean;
      justify-content: flex-start;
    }
  }
}

.recommendation-details {
  @media #{$md-down} {
    border-top: 1px solid $steam;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  &__title,
  &__content {
    font-family: $lato;
    letter-spacing: normal;
    line-height: 1.56;
    margin-bottom: 0.5rem;

    @media #{$md-down} {
      margin: 0 auto 10px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;

    @media #{$md-down} {
      margin: 0 auto 10px;
    }

    @media #{$md-down} {
      font-size: 16px;
    }

    @media #{$sm-down} {
      font-size: 15px;
    }
  }

  &__content {
    font-size: 17px;

    @media #{$md-down} {
      font-size: 16px;
    }

    @media #{$sm-down} {
      font-size: 15px;
    }
  }

  &__bottom-box {
    background-color: $white;
    border: solid 1px rgba(163, 163, 163, 0.3);
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    margin-left: 40px;
    margin-right: 30px;
    padding: 20px;

    @media #{$md-up} {
      bottom: 16px;
      margin-left: 0;
      position: absolute;
    }

    @media #{$md-down} {
      margin-left: 0;
      margin-right: 0;
      padding: 10px;
    }

    @media #{$sm-down} {
      display: flex;
    }
  }

  &__header,
  &__description {
    font-family: $lato;
    letter-spacing: normal;
    line-height: 1.56;

    @media #{$md-down} {
      margin: 10px auto;
    }
  }

  &__header {
    color: $og-link-blue;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 6px;

    @media #{$md-down} {
      font-size: 15px;
    }
  }

  &__description {
    font-size: 17px;

    @media #{$md-down} {
      font-size: 14px;
    }
  }

  &__image {
    margin: 8px 0;

    @media #{$sm-up} {
      height: 82px;
      width: 140px;
    }

    @media #{$sm-down} {
      margin-bottom: 10px;
    }

    @media #{$xxs-down} {
      display: block;
      margin: 0 auto 10px;
      max-height: 120px;
    }
  }
}

.plus-minus-icons {
  display: flex;
  font-size: 18px;
  margin-right: 0.5rem;
  margin-top: 5px;

  @media #{$md-down} {
    font-size: 16px;
  }

  @media #{$sm-down} {
    font-size: 14px;
  }

  &.bl-lt-icon--Plus::before,
  &.bl-lt-icon--Minus::before {
    color: $og-link-blue;
  }
}
