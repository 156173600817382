@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.credit-card {
  &__block {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
    text-align: center;
  }

  &__image-container {
    height: 120px;
    margin-bottom: 15px;

    @media #{$lg-down} {
      height: 140px;
    }

    @media #{$md-down} {
      height: 170px;
    }

    @media #{$sm-down} {
      height: 120px;
    }

    @media #{$xs-down} {
      height: auto;
    }
  }

  &__image {
    max-height: 100%;
  }

  &__title {
    color: $deep-azul;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 30px;
    text-align: center;

    @media #{$xs-down} {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 15px;
    }
  }

  &__link {
    margin-bottom: 8px;

    @media #{$xs-down} {
      margin-bottom: 6px;
    }
  }

  &__disclosures {
    height: 90px;

    @media #{$xs-down} {
      height: auto;
    }
  }

  &__secure-site {
    color: $smoke;
    font-size: 12px;
    margin-bottom: 10px;
    max-width: 160px;
  }

  &__rates-fees {
    color: $og-link-blue;
    font-weight: bold;
    margin-bottom: 3px;

    @media #{$xs-down} {
      font-size: 13px;
    }
  }

  &__terms-apply {
    color: $smoke;
    font-size: 13px;

    @media #{$xs-down} {
      font-size: 10px;
    }
  }
}

.credit-card-details {
  flex-grow: 1;
  margin-top: 30px;
  text-align: left;

  &__container {
    align-items: flex-start;
    border-top: 1px solid $steam;
    display: flex;
    flex-direction: column;
    line-height: 1;
    padding: 20px 15px;

    &:last-child {
      border-bottom: 1px solid $steam;
    }

    @media #{$xs-down} {
      margin: 0 -20px;
      padding: 20px 30px;
    }
  }

  &__title {
    color: $charcoal;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__toggle {
    color: $og-link-blue;
    cursor: pointer;
    margin-left: -10px;
    margin-top: 20px;

    &::before {
      color: $og-link-blue;
      content: '+';
      display: inline-block;
      font-size: 24px;
      height: 20px;
      line-height: 1;
      margin: 0 5px 0 0;
      padding: 1.5px 5px;
      vertical-align: -2px;
      width: 20px;
    }
  }

  &__toggle--opened {
    &::before {
      content: '-';
      padding: 1.5px 10px;
    }
  }

  ul {
    list-style: none;
    padding: 0 0 0 20px;
  }

  // We need to add some !important declarations b/c inline CSS may be returned from the Credit Card issuer
  li {
    line-height: 20px !important;
    list-style: none !important;
    padding-bottom: 10px !important;
    position: relative;

    &::before {
      color: $lt-green;
      content: '\2022';
      display: inline-block;
      margin-left: -1em;
      position: absolute;
      width: 1em;
    }
  }
}
