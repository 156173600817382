@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.loan-funded {
  margin-top: 1rem;

  &__record {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0.25rem;
    text-align: left;

    @media #{$md-up} {
      margin-bottom: 0;
    }

    &--label-name {
      color: $dark-gray;
      display: ruby;
      font-size: 15px;
      line-height: 1.47;
      margin-bottom: 3px;

      @media #{$sm-down} {
        font-size: 13px;
      }
    }

    &--label-val {
      font-size: 22px;

      @media #{$md-down} {
        font-size: 17px;
        font-weight: 700;
      }

      @media #{$sm-down} {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }

  &__content {
    margin-top: 1rem;

    &--label,
    &--text {
      font-size: 15px;
      line-height: 1.47;
    }

    &--label {
      color: $lt-green;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
