.animated-dots {
  // https://martinwolf.org/before-2018/blog/2015/01/pure-css-savingloading-dots-animation/
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  span {
    animation-duration: 1.4s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: blink;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
}
