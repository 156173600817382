@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';

.matche-offer {
  display: flex;

  .bl-lt-icon--Briefcase {
    font-size: 30px !important;

    &::before {
      color: $lt-green !important;
    }
  }

  &__box {
    padding: 0 10px;

    @media #{$sm-down} {
      padding: 0 10px 10px;
    }

    &--requested {
      color: $black !important;
      font-size: 17px !important;
      font-weight: bold;
      margin-bottom: 5px;

      @media #{$sm-down} {
        font-size: 15px !important;
      }
    }
    &--and {
      color: $smoke !important;
      font-size: 15px !important;
      font-weight: normal;
    }
    &--event-date {
      color: $smoke !important;
      font-size: 14px !important;

      @media #{$sm-down} {
        font-size: 13px !important;
      }
    }
  }
}
