@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.summary {
  background-color: $deep-azul;
  border-radius: 8px 0 0 8px;
  color: $white;
  padding: 25px 30px 30px;
  width: 278px;

  @media #{$md-down} {
    padding: 20px;
    width: 32%;
  }

  @media #{$sm-down} {
    border-radius: 8px 8px 0 0;
    padding: 15px 15px 30px;
    width: 100%;
  }

  &__label {
    color: $smoke;
    font-size: 15px;
    line-height: 0.7;
    margin-bottom: 10px;
  }

  &__number {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 15px;
    margin-bottom: 20px;

    &--todo-bold {
      color: $lt-green;
      font-weight: 700;
    }
  }

  &__action-cta {
    align-items: center;
    background: $og-link-blue;
    border: 1px solid transparent;
    border-radius: 3px;
    color: $white;
    display: flex;
    font-size: 17px;
    height: 50px;
    justify-content: center;
    width: 100%;

    @media #{$md-down} {
      font-size: 14px;
      height: 35px;
    }
    @media #{$xs-down} {
      padding: 6px 80px;
    }
    @media #{$xxxs-down} {
      padding: 6px 60px;
    }

    &:hover {
      background: $og-link-blue;
      color: $white;
      cursor: pointer;
    }
  }
}

.border-top-left-radius-zero {
  border-top-left-radius: 0;
}
