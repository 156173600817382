@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.profile-form {
  &__request-box {
    align-items: center;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(53, 53, 54, 0.25);
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 16px;
    padding: 25px 30px;
  }
  &__title-name {
    font-family: $lato;
  }
  &__label {
    margin-bottom: 20px;
  }
}
