@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.complete-profile {
  &__creditscore-box {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(53, 53, 54, 0.25);
    margin-top: 90px;
    padding: 25px 30px 30px;

    @media #{$md-down} {
      padding: 20px;
    }

    @media #{$sm-down} {
      margin-top: 10px;
      padding: 15px;
    }
  }

  &__creditscore-title {
    color: $deep-azul;
    font-family: $georgia;
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 10px;

    @media #{$sm-down} {
      font-size: 27px;
      letter-spacing: -0.1px;
      line-height: 1.19;
      margin-bottom: 10px;
    }
  }

  &__creditscore-subtitle {
    color: $deep-azul;
    font-family: $lato;
    font-size: 27px;
    height: 36px;
    letter-spacing: normal;
    line-height: 1.33;

    @media #{$sm-down} {
      font-size: 22px;
    }
  }

  &__business-text {
    font-family: $lato;
    font-size: 17px;
    margin-top: 20px;

    @media #{$sm-down} {
      font-size: 15px;
    }
  }

  &__left-side {
    max-width: 630px;

    @media #{$md-down} {
      width: 60%;
    }

    @media #{$sm-down} {
      max-width: 100%;
      width: 100%;
    }
  }

  &__right-imgblock {
    position: absolute;
    right: 0;
    top: -65px;

    @media #{$md-down} {
      top: -52px;
      width: 44%;
    }

    @media #{$sm-down} {
      padding-bottom: 1rem !important;
      position: relative;
      top: 30px;
      width: 100%;
    }
  }

  &__action-cta {
    align-content: space-between;
    align-items: center;
    background: $og-link-blue;
    border: 1px solid transparent;
    border-radius: 3px;
    color: $white !important;
    display: flex;
    flex-wrap: nowrap;
    font-size: 18px;
    height: 50px;
    justify-content: space-evenly;
    margin: 20px 0;
    max-width: 260px;
    padding: 12px;
    text-align: center;
    width: 100%;

    &:hover {
      color: $white !important;
      cursor: pointer;
    }

    @media #{$xs-down} {
      font-size: 15px;
      width: 100%;
    }
  }
}
