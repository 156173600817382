@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.resources-content {
  &__title {
    color: $deep-azul;
    font-family: $lato;
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 18px;
    padding-top: 50px;

    @media #{$sm-down} {
      font-size: 22px;
      padding-top: 20px;
    }
  }

  &__subtext {
    font-size: 22px;
    font-weight: normal;

    @media #{$sm-down} {
      font-size: 17px;
    }
  }

  .resource-block {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__image {
      margin-bottom: 25px;
    }

    &__button {
      margin-top: auto;
    }
  }
}
