@import '../../../styles/colors';

.select-input {
  &__container--large {
    .select-input__control {
      height: 50px;
      min-height: 50px;
    }
  }

  &__control {
    border: 1px solid $smoke !important;

    &.select-input__control--is-focused {
      &.select-input__control--menu-is-open {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }

  &__dropdown-indicator {
    padding: 3px !important;
  }
}
