@import '../../styles/breakpoints';
@import '../../styles/colors';

.incompleteApplication {
  &__modal {
    .modal__content--large {
      max-width: 600px;
      padding: 30px;
      text-align: center;
    }
  }

  &__content {
    @media #{$sm-down} {
      font-size: 15px;
    }
  }

  &__contact {
    border-bottom: 1px solid;
    border-top: 1px solid;
    display: flex;
    justify-content: center;
    padding: 10px 0;

    &--text {
      font-size: 15px;
      margin-bottom: 0;

      @media #{$sm-down} {
        font-size: 13px;
      }
    }

    &--number {
      color: $og-link-blue;
      cursor: pointer;
    }
  }

  &__button {
    margin: 40px 0;
    text-align: center;

    @media #{$sm-down} {
      margin: 20px 0;
    }
  }
}
