@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.profile-information {
  display: inline-block;

  @media #{$sm-down} {
    max-width: 235px;
    min-width: 235px;
  }

  @media #{$xxs-down} {
    max-width: 216px;
    min-width: 216px;
  }

  &__company-name {
    color: $lt-green;
    font-weight: bold;
    letter-spacing: 0.75px;
  }

  &__user-name {
    color: $deep-azul;
    font-weight: bold;
    line-height: 1.65;
  }

  &__phone {
    color: $og-link-blue;

    &--bold {
      font-size: 20px;
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__user-email {
    padding-right: 4px;
  }

  &__schedule-a-call {
    padding-left: 4px;
  }

  &__span {
    display: flex;
  }

  &__Schedule-A-Call {
    &--Modal {
      border: 0;
      height: 500px;
      width: 98%;
    }
  }

  &__call-or-text-block {
    display: flex;

    @media #{$sm-down} {
      display: block;
    }
  }

  &__call-or-text-cta {
    align-items: center;
    background: $white;
    border: 1px solid $ocean-blue;
    border-radius: 3px;
    color: $ocean-blue;
    display: flex;
    font-size: 17px;
    height: 50px;
    justify-content: center;
    margin: 0 15px;
    width: 50%;

    &:hover {
      background: $ocean-blue;
      color: $white;
    }

    @media #{$sm-down} {
      font-size: 15px;
      height: 45px;
      margin: 10px 0;
      width: 100%;
    }
  }
}
