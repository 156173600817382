@import '../../styles/breakpoints';
@import '../../styles/colors';

.user-agent {
  &__content {
    display: grid;
    justify-content: flex-end;

    @media #{$sm-down} {
      justify-content: initial;
      margin-bottom: 15px;
    }

    &--details {
      display: flex;
    }
  }
}
