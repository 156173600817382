@import '../../styles/colors';

@keyframes tabs-panel-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tabs {
  &__list {
    border-bottom: 1px solid $steam;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 30px;
  }

  &__item {
    background-color: transparent;
    border-bottom-color: transparent;
    border-bottom-width: 4px;
    border-left-width: 0;
    border-radius: 0;
    border-right-width: 0;
    border-top-width: 0;
    color: $og-link-blue;
    display: block;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 15px;
    text-transform: uppercase;

    &--active {
      border-bottom-color: $lt-green;
    }
  }

  &__panel {
    animation: 1s forwards tabs-panel-appear;
  }
}
