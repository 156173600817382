@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.notification_parent_container {
  width: 100%;
}
.notification_container {
  background-color: #fbebee;
  border: 1px solid #d93551;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  margin: 0 auto 30px;
  max-width: 1140px;
  overflow: hidden;
  padding: 10px;

  @media (max-width: 768px) {
    margin: 0 15px 30px;
    padding: 0 10px;
  }

  &_green {
    background-color: #effef8;
    border: 1px solid #069a5f;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    margin: 0 auto 30px;
    max-width: 1140px;
    overflow: hidden;
    padding: 10px;

    @media (max-width: 768px) {
      margin: 0 15px 30px;
      padding: 0 10px 10px;
    }
  }
}

.notification_body {
  &_action_container {
    align-items: center;
    display: flex;
    margin: 0 5px 5px auto;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &_centered_message_toprow_action_mobile {
    display: none;

    @media (max-width: 768px) {
      display: unset;
    }
  }

  &_container {
    display: flex;
    flex-basis: 95%;
  }

  &_container_flex_direction {
    display: flex;
    flex-direction: row;
    min-width: 100%;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  &_centered_container {
    margin-bottom: auto;
    margin-right: auto;
    margin-top: auto;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  &_ctaContainer {
    margin-left: auto;
    margin-right: 0;
    padding: 10px;
  }

  &_centered_message_container {
    .notification_body_centered_message_toprow_title {
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: unset;
    }
    .notification_message {
      margin-bottom: unset;
    }
  }

  &_centered_message_container_green {
    display: flex;
    flex-direction: row;

    .notification_body_centered_message_toprow_title {
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: unset;
    }
    .notification_message {
      margin-bottom: unset;
      margin-left: 10px;

      @media (max-width: 768px) {
        margin-left: unset;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &_centered_message_toprow_container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &_cta-mobile {
    @media (max-width: 768px) {
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}

.notification_state_icon_container {
  display: flex;
  margin-top: 5px;
  width: 25px;

  @media (max-width: 768px) {
    margin-top: 8px;
  }

  &_green {
    display: flex;
    margin-bottom: auto;
    margin-top: auto;
    width: 25px;

    @media (max-width: 768px) {
      margin-top: 8px;
    }
  }
}

.bl-lt-icon--Info-Icon {
  background-color: $white;
  border-radius: 10px;
  color: $color-lt-red;
  margin-right: 10px;
}

.button__close {
  color: $black;
  font-size: 24px;
  margin-left: 20px;

  &:hover,
  &:focus {
    color: $black;
    cursor: pointer;
    text-decoration: none;
  }

  @media #{$sm-down} {
    right: 10px;
    top: 0;
  }
}

.bl-lt-icon--Check::before {
  color: #069a5f !important;
}
