@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.resources-menu-link {
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(53, 53, 54, 0.25);
  color: $deep-azul;
  display: flex;
  font-size: 16px;
  padding: 8px 12px;
  width: 100%;

  @media #{$md-down} {
    font-size: 15px;
  }

  i {
    font-size: 25px;
    margin-right: 12px;

    &::before {
      color: $hopper-green;
    }
  }

  &--active {
    background: rgba(0, 178, 159, 0.1);
    border: 1px solid $hopper-green;
  }

  &:hover {
    background: rgba(0, 178, 159, 0.1);
    border: 1px solid $hopper-green;
    color: $deep-azul;
  }
}
