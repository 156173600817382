@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.existing-acct {
  &__box {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(53, 53, 54, 0.25);
    margin-top: 90px;
    padding: 25px 30px 30px;

    @media #{$md-down} {
      padding: 20px;
    }

    @media #{$sm-down} {
      margin-top: 10px;
      padding: 15px;
    }
  }

  &__title {
    font-family: $lato;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }

  &__container {
    height: 300px;
    margin: 0 auto;
    max-width: 525px;
    text-align: center;

    @media #{$sm-down} {
      height: auto;
    }

    @media #{$xs-down} {
      padding: 0 15px;
    }
  }

  &__copy {
    font-size: 15px !important;
    line-height: normal;
    margin-bottom: 1rem;
  }
}
