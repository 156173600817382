@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.congratulations {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 40px 40px;

  @media #{$md-down} {
    gap: 8px;
    padding: 0 16px 40px;
  }

  &__spinner {
    text-align: center;
    width: 100%;
  }

  &__header {
    color: $deep-azul;
    font-family: $lato;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    margin-bottom: 0;

    @media #{$md-down} {
      font-size: 27px;
      line-height: 36px;
    }
  }

  &__subheader {
    color: $dark-gray;
    font-family: $lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px;

    @media #{$md-down} {
      line-height: 24px;
    }
  }
}
.congratulations-body {
  display: flex;
  gap: 35px;
  justify-content: space-between;

  @media #{$md-down} {
    flex-direction: column;
  }

  .funding-expert {
    align-items: flex-start;
    background: $white;
    border-radius: 8px;
    /* Shadow/Shadow 3-light */
    box-shadow: 1px 3px 20px 1px rgba(0, 0, 0, 0.1), 1px 2px 7px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    padding: 24px;

    &__agent-details {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }

    &__agent-image {
      border-radius: 50%;
    }

    &__header {
      color: $deep-azul;
      /* Desktop/Bold/M */
      font-family: Lato;
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 133.333% */
      margin-bottom: 16px;
    }

    &__subheader {
      display: flex;
      gap: 15px;
    }

    &__block {
      display: flex;
      gap: 20px;
      margin-top: 5px;
      width: 100%;

      @media #{$sm-down} {
        display: flex;
        gap: 10px;
      }
    }

    &__cta {
      align-items: center;
      background: $white;
      border: 1px solid $secondary-blue;
      border-radius: 4px;
      color: $secondary-blue;
      display: flex;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      height: 50px;
      justify-content: center;
      line-height: 100%; /* 16px */
      text-align: center;
      width: 50%;

      @media #{$sm-down} {
        font-size: 14px;
        margin: 10px 0;
        width: 100%;
      }
    }

    &__agent-phone {
      color: $secondary-blue;
      cursor: pointer;
      display: flex;

      /* Body 2/Regular */
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      gap: 5px;
      line-height: 20px; /* 142.857% */
    }

    &__agent-hours {
      display: flex;
      gap: 5px;

      p:nth-child(1) {
        color: $dark-gray;

        /* Caption/Regular */
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
      p:nth-child(2) {
        color: $dark-gray;

        /* Caption/Bold */
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }

    &__agent-hours-label {
      color: $deep-azul;

      /* All Caps/All Caps 2/Bold */
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.6px;
      line-height: 16px; /* 133.333% */
      text-transform: uppercase;
    }

    &__agent-name {
      color: $deep-azul;
      font-family: Lato;
      font-feature-settings: 'clig' off, 'liga' off;
      /* Body 1/Bold */
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }

    &__content {
      color: $dark-gray;
      /* Body 1/Regular */
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-bottom: 16px;

      p:nth-child(2) {
        margin-bottom: 0;
      }
    }

    .bl-grove-icon--Envelope::before {
      font-size: 24px;
      margin-right: 5px;
    }
    .bl-grove-icon--Calendar::before {
      font-size: 24px;
      margin-right: 5px;
    }
    .bl-grove-icon--MobilePhone::before {
      font-size: 24px;
    }
  }

  .funding-status {
    align-items: flex-start;
    background: $white;
    border-radius: 8px;
    box-shadow: 1px 3px 20px 1px rgba(0, 0, 0, 0.1), 1px 2px 7px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 343px;

    &__header-container {
      padding: 16px 16px 0;
      width: 100%;
    }

    &__header-divider {
      border-bottom: 1px solid #c6cdd0;
      width: 100%;
    }

    &__header {
      color: $deep-azul;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.8px;
      line-height: 19px;
      text-transform: uppercase;
      width: 100%;
    }

    &__subheader {
      color: $gray-darken-3;
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.6px;
      line-height: 16px;
      margin-top: 6px;
      text-transform: uppercase;
    }

    &__row {
      display: block;
      width: 100%;
    }

    &__row-body {
      align-items: center;
      display: flex;
      height: 34px;
      justify-content: space-between;
      width: 100%;
    }

    &__row-content {
      align-items: center;
      display: flex;
    }

    &__body {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      padding: 0 16px 16px;
    }

    &__tooltip {
      color: $secondary-blue;
      font-size: 14px;
    }

    &__connector {
      background: #a0acb1;
      border-radius: 5px;
      height: 28.6px;
      margin-left: 15px;
      width: 2px;

      &.request-sent {
        background: $lt-green;
      }
      &.file-upload {
        background: linear-gradient(160deg, #ffcc4d 38.55%, #a0acb1 40.35%);
      }
      &.statement-reviewed {
        background: linear-gradient(160deg, #2cc78a 38.55%, #a0acb1 40.35%);
      }
    }

    &__icon-container {
      align-items: center;
      background: $white;
      border: 2px solid #a0acb1;
      border-radius: 44px;
      display: flex;
      height: 34px;
      justify-content: center;
      margin-right: 10px;
      width: 34px;

      &.request-sent {
        border: 2px solid $lt-green;
      }
      &.statement-reviewed {
        border: 2px solid $lt-green;
      }
      &.file-upload {
        border: 2px solid $twinkle-twinkle;
      }
    }

    .bl-lt-icon--Check::before {
      color: #08c177 !important;
    }
    .bl-lt-icon--Warning::before {
      color: #ffc507 !important;
      display: block;
      height: 16px;
      width: 15px;
    }

    &__content {
      color: $gray-darken-2;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

      &.request-sent {
        color: $gray-darken-2;
        font-feature-settings: 'clig' off, 'liga' off;
        font-weight: 700;
      }
      &.statement-reviewed {
        color: $gray-darken-2;
        font-feature-settings: 'clig' off, 'liga' off;
        font-weight: 700;
      }
      &.file-upload {
        color: $dark-gray;
        font-feature-settings: 'clig' off, 'liga' off;
        font-weight: 700;
      }
    }

    &__button-container {
      margin-left: auto;
    }

    &__upload-button {
      align-items: center;
      background-color: #fff;
      border: 1px solid $dark-gray;
      border-radius: 4px;
      color: $dark-gray;
      display: flex;
      font-family: Lato;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      gap: 10px;
      justify-content: center;
      line-height: 20px;
      padding: 4px 12px;
    }
  }
}
