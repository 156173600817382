@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.dashboard {
  &__fund-title {
    color: $deep-azul;
    font-family: $lato;
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 15px;

    @media #{$sm-down} {
      font-size: 22px;
    }
  }
  &__Schedule-A-Call {
    &--Modal {
      border: 0;
      height: 500px;
      width: 98%;
    }
  }

  &__tabs {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgb(53 53 54 / 25%);
  }
}

.tabs__list {
  @media #{$sm-down} {
    flex-wrap: nowrap;
    padding: 0;
  }
}
