@import '../../styles/colors';

.input-group {
  flex-direction: column;
  margin-bottom: 20px;

  &--error {
    input,
    select,
    .select-input__control {
      border: 1px solid $fashion-fuchsia;
      color: $fashion-fuchsia;
    }

    .select-input__control {
      border: 1px solid $fashion-fuchsia !important;
    }
  }
}
