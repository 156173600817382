@import '../../styles/colors';

.text-input {
  border: 1px solid $smoke;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  padding: 8px 12px;
  width: 100%;

  &--large {
    height: 50px;
    padding: 12px;
  }

  &.invalid {
    background: $fluffy;
    border: 1px solid $fashion-fuchsia;
    color: $fashion-fuchsia;
  }
}

.text-input:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: #495057;
  outline: 0;
}
