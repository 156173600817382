@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.public-navbar {
  color: $white;
  margin: 0 auto;
  padding: 10px;

  @media #{$xs-up} {
    max-width: 540px;
  }

  @media #{$sm-up} {
    max-width: 720px;
  }

  @media #{$md-up} {
    max-width: 960px;
  }

  @media #{$lg-up} {
    max-width: 1140px;
  }

  &__row {
    align-items: center;
  }

  &__column {
    align-items: center;
    display: flex;

    &--back {
      align-items: start;
    }

    &--login {
      align-items: end;
    }
  }

  &__logo {
    width: 150px;

    @media #{$md-down} {
      width: 130px;
    }

    @media #{$sm-down} {
      width: 110px;
    }

    @media #{$xs-down} {
      width: 100px;
    }
  }

  &__coverdashlogo {
    img {
      height: 40px;
      max-width: unset;
      width: 350px;
    }
  }

  &__back {
    align-items: center;
    color: $lt-green;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.67px;
    text-transform: uppercase;

    &:hover {
      color: $lt-green;
    }
  }

  &__back-text {
    margin-left: 5px;
  }
}
