@import '../../../styles/colors';

.lender-details {
  border-bottom: 1px solid $steam;
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }

  &__name {
    color: $black;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.47;
    margin-bottom: 13px;
  }

  &__row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 15px;
    }
  }

  &__label-name,
  &__label-value {
    font-size: 15px;
    line-height: 1.47;
  }

  &__label-name {
    color: $dove-gray;
    font-size: 15px;
    line-height: 1.47;
  }

  &__label-value {
    color: $black;
    font-weight: bold;
  }
}
