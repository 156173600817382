@import '../../../styles/colors';

.renewals-progress {
  position: relative;

  &__sections {
    display: flex;
  }

  &__bar {
    background-color: $color-light-grey216;
    border-radius: 100px;
    height: 10px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    &--pct {
      background-color: $lt-green;
      height: 100%;
    }
  }

  &__section {
    border-right: 2px solid $white;
    flex: 1;
    margin: 0 2px;
    z-index: 2;

    &-label {
      margin-top: 2em;
      text-align: right;
    }

    &:last-child {
      border-right: unset;
    }
  }
}
