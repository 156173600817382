@import '../../../styles/colors';

.credit-card-disclosures {
  font-size: 12px;
  padding-top: 18px;

  &__link {
    color: $og-link-blue;
    cursor: pointer;
  }
}
