@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.document-request,
.document-request--disabled {
  background-clip: border-box;
  background-color: $white;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(53, 53, 54, 0.25);
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem !important;
  min-width: 0;
  padding: 30px;
  position: relative;
  word-wrap: break-word;

  @media #{$sm-down} {
    padding: 15px;
  }

  &--disabled {
    opacity: 50%;
  }

  &__header {
    border-bottom: 0;
    cursor: pointer;
    padding: 0;
    position: relative;

    &--cta {
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 3px;
      color: $og-link-blue;
      float: right !important;
      font-size: 15px;
    }

    &--content {
      align-items: center;
      display: flex;
      justify-content: space-between;

      @media #{$sm-down} {
        align-items: flex-start;
        flex-direction: column;
      }

      &--application-number {
        color: $black;
        font-size: 17px;
        font-weight: bold;
      }

      &--req-amount {
        align-items: center;
      }

      &--amount-text {
        color: $grey-day;
        font-size: 15px;
        line-height: 1.47;
      }

      &--amount-value {
        color: $deep-azul;
        font-size: 17px;
        font-weight: bold;
        margin-left: 7px;
      }
    }

    &--content--application-status {
      color: $smoke;
      margin-left: 40px;

      @media #{$md-down} {
        font-weight: bold;
        margin: 0.5rem 0;
      }
    }

    &--plus-minus-icons {
      padding-left: 20px;
      padding-top: 12px;

      @media #{$sm-down} {
        padding: 15px 0;
      }

      &.bl-lt-icon--Plus::before,
      &.bl-lt-icon--Minus::before {
        color: $og-link-blue;
      }
    }
  }

  &__body {
    &--statement-text {
      color: $dark-gray;
      font-size: 15px;
      line-height: 1.47;
      margin-top: 15px;
    }
    &--todo-text {
      color: $lt-green;
      font-weight: bold;
    }
  }
}

.pointer__events {
  pointer-events: none;
}
