@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.password-signup-form {
  margin: auto;
  max-width: 620px;
  text-align: left;

  &__submit-button {
    margin-top: 15px;
  }
}

.error-progress-block {
  margin-top: 1rem;

  &__box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-left: 0;
    margin-right: 0;

    &--bar {
      background: $smoke;
      border-radius: 2px;
      height: 4px;
      width: calc(33.33% - 12px);
    }

    &--red-bar {
      background: $fashion-fuchsia;
      color: $fashion-fuchsia;
    }

    &--green-bar {
      background: $hopper-green;
      color: $hopper-green;
    }

    &--blue-bar {
      background: $ocean-blue;
      color: $ocean-blue;
    }

    &--red-text {
      color: $fashion-fuchsia;
    }

    &--green-text {
      color: $hopper-green;
    }

    &--blue-text {
      color: $ocean-blue;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    text-align: initial;

    &--item {
      font-size: 13px;
      line-height: 1.54;
    }
  }

  &__bl-lt-icon {
    display: inline-block;
    font-size: 11px;
    margin-right: 5px;
  }

  .bl-lt-icon--Check {
    &::before {
      color: $hopper-green;
    }
  }

  .bl-lt-icon--Ex {
    &::before {
      color: $fashion-fuchsia;
    }
  }
}

.show-text {
  color: #666;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  position: absolute;
  right: 12px;
  top: 39px;
}

.signup__subtitle {
  font-size: 17px;
  margin: 0 auto 20px;
  max-width: 430px;
  padding: 0 15px;
}

.signup__footer {
  margin-top: 20px;
  text-align: center;
}
