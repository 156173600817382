@import '../../styles/colors';

.button {
  background: $og-link-blue;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  color: $white;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  padding: 15px 20px;
  text-shadow: none;
  text-transform: none;
  transition: all 150ms ease-in-out;

  &--large {
    font-size: 18px;
    height: 50px;
  }

  &--compact {
    padding: 10px 15px;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--loading {
    svg {
      animation: rotate 2s linear infinite;
      height: 35px;
      margin-top: -8px;
      width: 35px;

      circle {
        animation: dash 1.5s ease-in-out infinite;
        stroke: currentColor;
        stroke-linecap: round;
      }
    }
  }

  &:disabled {
    opacity: 0.65;
    pointer-events: none;
  }
}

.button--blue {
  @extend .button;

  &:hover {
    background: $ocean-blue;
  }
}

.button--blue-outline {
  @extend .button;
  background: $white;
  border: 1px solid $ocean-blue;
  color: $ocean-blue;

  &:hover {
    background: $ocean-blue;
    color: $white;
  }
}

.button--lt-blue-outline {
  @extend .button;
  background: $white;
  border: 1px solid $og-link-blue;
  color: $og-link-blue;
  font-weight: bold;

  &:hover {
    background: $og-link-blue;
    color: $white;
  }
}

.button--orange {
  @extend .button;
  background: $electric-salmon;

  &:hover {
    background: #eb5843;
  }
}

.button--gray {
  @extend .button;
  background: $smoke;

  &:hover {
    background: $grey-day;
  }
}

.button--nocolor {
  @extend .button;
  align-items: center;
  background: none;
  border: 1px solid #454545;
  border-radius: 4px;
  color: #454545;
  display: flex;
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 30px;
  line-height: 20px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
