@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.renewals {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(53, 53, 54, 0.25);

  &__header {
    align-items: center;
    border-bottom: 1px solid $light-gray;
    display: flex;
    padding-left: 30px;

    @media #{$sm-down} {
      padding-left: 15px;
    }

    &--id {
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 7px 0;
      text-transform: uppercase;

      @media #{$md-down} {
        font-size: 13px;
      }
    }
  }

  &__content {
    display: flex;
    margin-bottom: 1rem;

    @media #{$sm-down} {
      flex-direction: column;
    }
  }

  &__summary {
    background-color: $deep-azul;
    border-radius: 0 0 0 8px;
    color: $white;
    padding: 25px 30px 30px;
    width: 278px;

    @media #{$md-down} {
      padding: 20px;
      width: 32%;
    }

    @media #{$sm-down} {
      border-radius: 0;
      padding: 15px 15px 30px;
      width: 100%;
    }

    &-heading {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 10px;
      position: relative;
    }

    &-text {
      font-size: 15px;
      margin-bottom: 20px;
    }

    &-cta {
      align-items: center;
      background: $og-link-blue;
      border: 1px solid transparent;
      border-radius: 3px;
      color: $white;
      display: flex;
      font-size: 17px;
      height: 50px;
      justify-content: center;
      width: 100%;

      @media #{$md-down} {
        font-size: 14px;
        height: 35px;
      }

      &:hover {
        background: $og-link-blue;
        color: $white;
        cursor: pointer;
      }
    }
  }

  &__summary-heading-icon {
    font-size: 13px;
    position: absolute;
    right: 5px;
    top: 0;
    z-index: 2;

    .bl-lt-icon--Info-Icon {
      background-color: $white;
      border-radius: 10px;
      color: $og-link-blue;
    }
  }

  &__status {
    background-color: $white;
    border-radius: 0 0 8px 8px;
    color: $deep-azul;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    position: relative;
    width: calc(100% - 278px);

    @media #{$md-down} {
      padding: 20px;
      width: 68%;
    }

    @media #{$sm-down} {
      padding: 20px 15px;
      width: 100%;
    }

    &-progress {
      flex: 1;
      transform: translateY(-17px);
    }

    &-content {
      display: flex;
      font-size: 22px;
      justify-content: space-between;
      text-align: left;

      @media #{$sm-down} {
        font-size: 15px;
        font-weight: 700;
      }

      &-block {
        flex: 0 0 calc(25% - 22.5px);
        max-width: calc(25% - 22.5px);
      }

      &-label {
        color: $dark-gray;
        font-size: 68%;
        font-weight: normal;
        margin-bottom: 4px;
      }
    }

    &--em-icon {
      align-items: center;
      color: $deep-verde;
      display: flex;
      flex-direction: column;
      font-size: 13px;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
      z-index: 1;

      &-label {
        width: 120px;
      }
    }

    &--em-container {
      height: 50px;

      @media #{$md-down} {
        height: 60px;
      }

      @media #{$sm-down} {
        height: 15px;
      }
    }

    &--em-icon-container {
      color: $deep-verde;
      display: block;
      font-size: 13px;
      font-weight: 700;
      text-align: center;

      .renewals__status--em-info-icon {
        margin: 0;
      }
    }

    &--em-icon-label {
      @media #{$sm-down} {
        width: inherit;
      }
    }

    &--em-info-icon {
      color: $vivid-cerulean;
      margin: 0 0 -16px 105px;
      z-index: 1;

      @media #{$sm-down} {
        margin: 0 0 -16px 185px;
      }
    }

    &--ep-icon {
      align-items: center;
      color: $smoke;
      display: inline-flex;
      font-size: 13px;
      left: 32px;
      position: absolute;
      top: 36px;
      z-index: 2;

      &-label {
        margin-right: 0.5em;
      }

      .bl-lt-icon--Info-Icon {
        color: $og-link-blue;
        padding-right: 0.3em;
      }

      @media #{$sm-down} {
        align-items: center;
        color: $dark-gray;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        margin: -0.5em 0 0.5em;
        position: unset;
        z-index: 1;
      }
    }
  }
}
