@import '../../styles/breakpoints';
@import '../../styles/colors';

.page-title {
  &__sub-header {
    color: $dark-gray;
    font-size: 17px;
    letter-spacing: normal;
    line-height: 1.65;

    @media #{$sm-down} {
      font-size: 15px;
      line-height: 1.47;
    }
  }
}
