@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.navbar-dropdown {
  align-items: center;
  display: flex;
  flex-grow: 1;

  .navbar-link.navbar-dropdown__navbar-link {
    align-items: center;
    display: flex;
    margin-left: auto;

    &--close {
      .navbar-link__icon {
        font-size: 14px;
        margin-left: 3px;
        margin-right: 18px;
      }
    }

    @media #{$sm-down} {
      &::after {
        content: '\e98a';
        display: block;
        font-family: $lt5;
        position: absolute;
        right: 20px;
      }
    }

    @media #{$sm-up} {
      border: 0;
      display: flex;
      flex-direction: row-reverse;
      font-size: 15px;
      padding: 0;

      .navbar-link__icon {
        margin-left: 10px;
      }
    }
  }
}

.navbar-dropdown__menu {
  background-color: $white;
  border: 0;
  border-radius: 6px;
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.3);
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 60px;
  transition: 0.5s ease all;
  visibility: hidden;
  width: 220px;
  z-index: 1;

  @media #{$sm-down} {
    background-color: $deep-azul;
    border-radius: 0;
    height: 100%;
    left: 0;
    transform: translateX(-255px);
    width: 255px;
  }

  &--show {
    opacity: 1;
    transform: translateX(0);
    transition: 0.5s ease all;
    visibility: visible;
  }

  .navbar-link {
    @media #{$sm-up} {
      align-items: center;
      border-bottom: 1px solid rgba(69, 69, 69, 0.2);
      color: $dark-gray;
      display: flex;
      flex-direction: row;
      font-size: 14px;
      padding: 10px;
      text-decoration: none;

      &__icon {
        font-size: 18px;
        margin-right: 10px;
      }

      &__icon::before {
        color: $lt-green;
      }

      &--active,
      &:hover {
        background-color: rgba(8, 193, 119, 0.2);
        color: $dark-gray;
      }
    }
  }
}
