@import '../../../styles/breakpoints';
@import '../../../styles/fonts';
@import '../../../styles/colors';

.lendability {
  padding-left: 40px;
  padding-right: 40px;

  @media #{$sm-down} {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__header {
    padding-top: 20px;

    &--title {
      font-family: $lato;
    }

    &--text {
      color: $dark-gray;
      font-family: $lato;
      font-size: 17px;
      letter-spacing: normal;
      line-height: 1.65;
      margin-bottom: 30px;
      margin-top: 10px;

      @media #{$sm-down} {
        font-size: 16px;
        line-height: 1.47;
      }

      @media #{$sm-down} {
        font-size: 15px;
        line-height: 1.47;
      }
    }
  }

  &__content {
    @media #{$md-down} {
      margin: 0;
    }
  }
}
