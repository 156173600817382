@import '../../styles/colors';

/* stylelint-disable-next-line plugin/stylelint-bem-namics */
.ltFormOverlay {
  display: none;
}

.inside-form {
  &--error {
    color: $fashion-fuchsia;
    text-align: center;
  }
}
