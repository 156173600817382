@import '../../../styles/breakpoints';

.exchange-request {
  filter: drop-shadow(0 2px 3px rgba(53, 53, 54, 0.25));

  &__block {
    border-radius: 8px;
    display: flex;

    @media #{$sm-down} {
      border-radius: 0 0 8px 8px;
      flex-direction: column;
    }
  }
}
