@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.navbar {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  position: relative;
  width: 100%;

  @media #{$sm-down} {
    padding: 0 20px;
  }

  @media #{$sm-up} {
    max-width: 720px;
  }

  @media #{$md-up} {
    max-width: 960px;
  }

  @media #{$lg-up} {
    max-width: 1140px;
  }

  &__logo {
    align-items: center;
    cursor: pointer;
    display: flex;

    @media #{$sm-down} {
      justify-content: center;
      width: 100%;
    }

    img {
      max-width: 100%;
      width: 160px;
    }
  }

  &__drawer-toggle {
    cursor: pointer;
    display: none;
    font-size: 1.25rem;
    margin-top: 5px;

    @media #{$sm-down} {
      display: block;
    }

    i::before {
      color: $og-link-blue;
    }
  }

  &__close {
    cursor: pointer;
    margin-top: 10px;

    @media #{$sm-up} {
      display: none;
    }
  }

  &__collapse {
    align-items: center;
    display: flex;
    flex-basis: auto;
    flex-grow: 1;

    @media #{$sm-down} {
      background: $deep-azul;
      display: block;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      transform: translateX(-255px);
      transition: all 0.5s ease;
      visibility: hidden;
      width: 255px;
      z-index: 1;
    }
  }

  &__collapse--show {
    @media #{$sm-down} {
      left: 0;
      opacity: 1;
      transform: translateY(0);
      transition: 0.5s ease all;
      visibility: visible !important;
      z-index: 2;
    }
  }

  &__nav {
    display: flex;
    flex-grow: 1;
    margin-left: 30px;

    @media #{$sm-down} {
      flex-direction: column;
      margin-left: 0;
    }
  }
}
