@import '../../styles/breakpoints';

// TODO: rename this to `.container` once Boostrap is removed
.app__container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;

  @media #{$xs-down} {
    padding: 0;
  }

  @media #{$xs-up} {
    max-width: 540px;
  }

  @media #{$sm-up} {
    max-width: 720px;
  }

  @media #{$md-up} {
    max-width: 960px;
  }

  @media #{$lg-up} {
    max-width: 1140px;
  }
}
