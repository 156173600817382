@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.upload-documents-table {
  color: #212529;
  margin-bottom: 1rem;
  margin-top: 30px;
  width: 100%;

  @media #{$sm-down} {
    margin-top: 15px;
  }

  &__thead {
    @media #{$sm-down} {
      display: none;
    }

    &-th {
      border-bottom: 2px solid $dark-gray;
      color: $dark-gray;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: normal;
      padding: 10px 15px;
      text-transform: uppercase;

      &:first-child {
        width: 40%;
      }
    }
  }

  &__tbody {
    &-tr {
      position: relative;

      @media #{$sm-down} {
        border-bottom: 1px solid $smoke;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
      }
    }
  }

  &__td {
    border-bottom: 1px solid #d8d8d8;
    color: $dark-gray;
    font-size: 15px;
    line-height: normal;
    padding: 20px 15px;

    @media #{$sm-down} {
      border: 0;
      padding: 0;
    }

    &:nth-child(2) {
      width: 40%;
      word-break: break-all;

      @media #{$sm-down} {
        color: #000;
        font-weight: bold;
        line-height: 1.33;
        margin-bottom: 3px;
        width: 100%;
      }
    }

    &:first-child {
      @media #{$sm-down} {
        line-height: 1.33;
        margin-bottom: 2px;
      }
    }

    &:nth-child(3) {
      @media #{$sm-down} {
        font-size: 12px;
        line-height: 1.67;
        margin-bottom: 2px;
      }
    }

    &:last-child {
      @media #{$sm-down} {
        padding: 0;
      }
    }
  }
  &__inprocess {
    text-align: center;

    &--blink {
      animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }
}

.upload-documents-wrapper {
  margin-bottom: 10px;
}
