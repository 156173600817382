@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.tree-auth-signup {
  &__title {
    color: $lt-green;
    font-size: 19px;
    font-weight: bold;
    line-height: 2.5;
    margin-bottom: 9px;
    text-align: center;
    text-transform: uppercase;

    @media #{$sm-down} {
      font-size: 16px;
    }
  }

  &__header {
    color: $deep-azul;
    font-size: 40px;
    margin-bottom: 20px;
    text-align: center;

    @media #{$sm-down} {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }

  &__container {
    margin: auto;
    max-width: 620px;
  }

  &__why {
    margin-bottom: 20px;
    text-align: center;

    &--strong {
      font-weight: bold;
    }
  }

  &__error-message {
    margin-bottom: 20px;
    text-align: center;

    @media #{$sm-down} {
      margin-bottom: 30px;
    }
  }
}
