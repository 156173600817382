@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.upload {
  margin: 0;
  position: relative;
  text-align: center !important;
  width: 100%;

  @media #{$sm-up} {
    border: 2px dashed $og-link-blue;
    border-radius: 4px;
    cursor: pointer;
    padding: 40px;
  }

  &__hidden {
    display: none;
  }

  &__file {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__img {
    @media #{$sm-down} {
      display: none;
    }
  }

  &__text {
    color: $og-link-blue;
    display: block;
    font-size: 17px;

    @media #{$sm-down} {
      display: none;
    }
  }

  button {
    @media #{$sm-up} {
      display: none;
    }
  }

  &__icon {
    color: $og-link-blue;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    width: 20px;

    @media #{$sm-down} {
      position: absolute;
      right: 18px;
      top: 13px;
    }

    i {
      &::before {
        color: inherit;
      }
    }

    &:hover {
      color: $smoke;
    }

    &-arrow {
      display: inline-block;
      transform: rotate(90deg);
    }

    &-rectangle {
      border: solid 2px;
      border-radius: 0.75px;
      border-top-style: hidden;
      bottom: 13px;
      display: inline-block;
      height: 8px;
      position: relative;
      width: 15px;
    }
  }
}

.upload__modal-container {
  .doc-size {
    color: $smoke;
    text-align: right;
  }

  .progressbar-container {
    height: 5px;
  }

  @media #{$sm-up} {
    .upload {
      margin-bottom: 10px;
    }

    button {
      margin-left: 25px;
      width: 184px;
    }
  }

  .cta-action-block {
    display: flex;
    justify-content: end;
    margin-left: auto;
    padding: 10px 0;

    @media #{$sm-down} {
      flex-direction: column-reverse;

      button:last-child {
        margin-bottom: 10px;
      }
    }
  }

  .error {
    color: $fashion-fuchsia;
    white-space: nowrap;
    word-break: initial;

    @media #{$sm-down} {
      white-space: normal;

      &__message {
        padding-left: 25px;
      }
    }
  }

  .bl-lt-icon--Check {
    padding-left: 15px;

    &::before {
      color: $steam;
    }

    &.isFulfilled::before {
      color: $lt-green;
    }

    @media #{$sm-down} {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  .bl-lt-icon--Ex {
    padding-right: 10px;

    &::before {
      color: $fashion-fuchsia;
    }

    @media #{$sm-down} {
      float: left;
      padding: 0;
    }
  }

  .upload-documents-table__td {
    .select-input__container {
      width: 250px;
    }

    &:nth-child(2) {
      word-break: break-word;

      .modal__wrapper {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    @media #{$sm-up} {
      &:first-child {
        max-width: 300px;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:last-child {
        max-width: 60px;
      }
    }

    @media #{$sm-down} {
      .upload-document__td-delete-icon {
        float: left;
        margin: 0;
      }

      &:first-child {
        font-weight: bold;
        margin-bottom: 0.5rem;
        padding-right: 0;
        word-break: break-all;
      }

      &:nth-child(2) {
        font-weight: normal;
      }

      .select-input__container {
        margin-left: 25px;
        width: unset;
      }

      .doc-size {
        float: right;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}

.upload__modal-wrapper {
  max-height: 460px;
  overflow-y: scroll;
}

.scrollbar {
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar-track {
    background-color: $fluffy;
  }
  &::-webkit-scrollbar {
    background-color: $fluffy;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $light-grey;
  }
}
