@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

.account-signup-form {
  margin: auto;
  max-width: 620px;
  text-align: left;

  &__submit-button {
    margin-top: 15px;
  }
}
